import React from 'react'
import styled from 'styled-components'
import PhoneIcon from './PhoneIcon'
import MapIcon from './MapIcon'
import MailIcon from './MailIcon'
import { CompanyInfo } from '../constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 2rem 0;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem;
  cursor: pointer;

  @media (min-width: 992px) {
    width: 33%;
  }

  div {
    margin: 1.5rem 0 0 0;
  }

  .address {
    color: var(--white);
    font-size: 1.6rem;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    line-height: 2rem;
  }

  .email {
    color: var(--white);
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
    text-decoration: underline;
  }
`

const FooterInfo = () => {
  const phoneUrl = `tel:${CompanyInfo.phone}`
  const emailUrl = `mailto:${CompanyInfo.email}`

  const onPhone = () => {
    window.open(phoneUrl)
  }

  const onMap = () => {
    window.open(CompanyInfo.route, '_blank')
  }

  const onEmail = () => {
    window.open(emailUrl)
  }

  return (
    <Container>
      <Info onClick={onPhone}>
        <PhoneIcon />
        <div>
          <a className="phone" href={phoneUrl}>
            {CompanyInfo.phone}
          </a>
        </div>
      </Info>

      <Info onClick={onMap}>
        <MapIcon />
        <div className="address">Как нас найти</div>
      </Info>

      <Info onClick={onEmail}>
        <MailIcon />
        <div>
          <a className="email" href={emailUrl}>
            {CompanyInfo.email}
          </a>
        </div>
      </Info>
    </Container>
  )
}

export default FooterInfo
