import React from 'react'
import styled from 'styled-components'
import Gallery from './Gallery'
import FooterInfo from './FooterInfo'
import FooterMenu from './FooterMenu'

const FooterWrapper = styled.footer`
  padding: 6rem 2rem 2rem 2rem;
  background-color: var(--black);
  color: var(--yellow);

  @media (max-width: 576px) {
    padding-bottom: 1rem;
  }

  .footer-wrapper {
    max-width: 1300px;
    margin: 0 auto 0 auto;
  }

  .copyright {
    padding: 2rem 0 2rem 0;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    text-align: center;
  }
`

const Footer = () => (
  <FooterWrapper>
    <div className="footer-wrapper">
      <Gallery />
      <FooterInfo />
      <FooterMenu />
      <div className="copyright">
        &copy; {new Date().getFullYear()} База отдыха "Золотая звезда"
      </div>
    </div>
  </FooterWrapper>
)

export default Footer
