import { createGlobalStyle } from 'styled-components'

import MontserratBold from '../assets/fonts/Montserrat-Bold.ttf' 
import NeuchaRegular from '../assets/fonts/Neucha-Regular.ttf' 
import RobotoSlab from '../assets/fonts/RobotoSlab-VariableFont_wght.ttf' 
import MontserratLight from '../assets/fonts/Montserrat-Light.ttf' 
import CormorantBold from '../assets/fonts/CormorantSC-Bold.ttf'
import OpenSans from '../assets/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../assets/fonts/OpenSans-Bold.ttf'
import OpenSansLight from '../assets/fonts/OpenSans-Light.ttf'
import Tetra from '../assets/fonts/Tetra.otf'

const Typography = createGlobalStyle`
  @font-face {
    font-family: 'Roboto Slab';
    src: url(${RobotoSlab})
  }

  @font-face {
    font-family: 'Neucha Regular';
    src: url(${NeuchaRegular})
  }

  @font-face {
    font-family: 'Montserrat Bold';
    src: url(${MontserratBold})
  }

  @font-face {
    font-family: 'Montserrat Light';
    src: url(${MontserratLight})
  }

  @font-face {
    font-family: 'Cormorant SC Bold';
    src: url(${CormorantBold})
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSans})
  }

  @font-face {
    font-family: 'Open Sans Bold';
    src: url(${OpenSansBold})
  }

  @font-face {
    font-family: 'Open Sans Light';
    src: url(${OpenSansLight})
  }

  @font-face {
    font-family: 'Tetra';
    src: url(${Tetra})
  }

  HTML {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }

  a {
    color: var(--yellow);
    text-decoration: none;
  }

  .center {
    text-align: center;
  }
`

export default Typography
