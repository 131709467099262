import { createGlobalStyle } from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #0C020A;
    --yellow: #FBB438;
    --white: #fff;
    --grey: rgba(0, 0, 0, 0.7);
    --light-grey: rgba(0, 0, 0, 0.05);
  }

  html {
    font-size: 10px;
    color: var(--black);
  }

  body {
    font-size: 1.7rem;
    font-family: "Open Sans", sans-serif;
    background-color: transparent;
  }

  h1 {
    font-family: 'Roboto Slab',serif;
    font-weight: bold;
    font-size: 5rem;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 4rem;
    }

    @media (max-width: 576px) {
      font-size: 2.5rem;
    }
  }

  p {
    line-height: 2.6rem;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.7rem;
    text-align: justify;
  }

  li {
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.7rem;
    line-height: 2.6rem;
    padding-bottom: 0.8rem;
  }

  img {
    max-width: 100%;
  }

  td {
    font-size: 1.7rem;
    font-family: 'Open Sans', sans-serif;
    color: rgba(0, 0, 0, 0.7);
  }

  .title {
    font-family: 'Cormorant SC Bold', serif;
    font-weight: bold;
    font-size: 2.5rem;
    color: var(--black);
    text-transform: uppercase;

    @media (max-width: 576px) {
      font-size: 2rem;
    }
  }

  .subheading {
    color: var(--white);
    font-family: 'Open Sans Light', sans-serif;
    font-weight: 300;
    font-size: 1.8rem;
    text-align: center;
    line-height: 2.5rem;
    text-transform: none;

    @media (max-width: 576px) {
      font-size: 1.6rem;
    }
  }

  .phone {
    font-family: 'Open Sans Bold', sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: var(--white);
  }

  .slick-slide, .slick-slide *{ outline: none !important; }
`

export default GlobalStyles
