import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 3rem;
  height: 3rem;

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: var(--yellow);
  }
`

const MapIcon = () => (
  <Container>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56.5"
      height="56.812"
      viewBox="0 0 56.5 56.812"
    >
      <path
        d="M28.253 16.651a1.5 1.5 0 0 0 0-2.99 1.793 1.793 0 1 1 1.785-1.794 1.488 1.488 0 1 0 2.975 0 4.76 4.76 0 1 0-4.76 4.784zm20.489-6.482h-2.5a1.495 1.495 0 0 0 0 2.989h2.5a4.806 4.806 0 0 1 4.8 4.8v10.748H40.283a1.491 1.491 0 0 0-1.488 1.5v13.1H21.206a5.683 5.683 0 0 0-4.011-4.028V30.45a1.864 1.864 0 0 0-1.554-1.752H2.966V17.962a4.806 4.806 0 0 1 4.8-4.8h2.451a1.495 1.495 0 0 0 0-2.989H7.766a7.791 7.791 0 0 0-7.771 7.793v31.04A7.791 7.791 0 0 0 7.766 56.8h40.98a7.792 7.792 0 0 0 7.773-7.794V17.962a7.792 7.792 0 0 0-7.777-7.793zM2.966 31.69h11.259v7.616a5.686 5.686 0 0 0-3.926 4.006H2.966V31.69zm11.259 22.121H7.762a4.806 4.806 0 0 1-4.8-4.8v-2.708h7.349a5.683 5.683 0 0 0 3.91 3.943v3.57zm24.57 0h-21.6v-4.5a1.541 1.541 0 0 0 .043-.343 1.491 1.491 0 0 0-1.488-1.494 2.7 2.7 0 1 1 2.678-2.7 1.49 1.49 0 0 0 1.487 1.5 1.428 1.428 0 0 1 1.084.031H38.79v7.513zm14.745-4.8a4.806 4.806 0 0 1-4.8 4.8h-6.972v-7.513h11.77v2.708zm0-5.7H41.77V31.69h11.77v11.616zm-7.534-5.595a1.794 1.794 0 1 0-1.785-1.793 1.789 1.789 0 0 0 1.785 1.793zm-29-20.267l9 19.165a2.465 2.465 0 0 0 4.468 0l9-19.16a1.634 1.634 0 0 0 .071-.177 12.3 12.3 0 1 0-22.65-.1 1.92 1.92 0 0 0 .111.272zM28.25 2.995a9.4 9.4 0 0 1 8.53 13.192l-.027.069-8.508 18.11-8.512-18.115v-.014a1.456 1.456 0 0 0-.057-.156 9.4 9.4 0 0 1 8.577-13.086z"
        fillRule="evenodd"
      ></path>
    </svg>
  </Container>
)

export default MapIcon
