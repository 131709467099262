import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 2rem;
  height: 2rem;

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: var(--black);
  }
`

const CloseIcon = () => (
  <Container>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path
        d="M512 30.17L481.83 0 256 225.83 30.17 0 0 30.17 225.83 256 0 481.83 30.17 512 256 286.17 481.83 512 512 481.83 286.17 256z"
        fillRule="evenodd"
      ></path>
    </svg>
  </Container>
)

export default CloseIcon
