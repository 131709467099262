import React from 'react'
import styled from 'styled-components'

import HumburgerIcon from './HumburgerIcon'

type Props = {
  onClick: () => void
}

const DivStyled = styled.div`
  display: flex;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    position: absolute;
    top: 0;
    left: 0;
  }

  background-color: var(--yellow);
  color: var(--black);
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  transition: all 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.1);
  }

  .dark {
    &:hover {
      box-shadow: 7px 7px 0 0 rgba(255, 255, 255, 0.2);
    }
  }
`

const MenuButton = ({ onClick }: Props) => {
  return (
    <DivStyled {...{ onClick }}>
      <HumburgerIcon />
    </DivStyled>
  )
}

export default MenuButton
