import React from 'react'
import styled from 'styled-components'
import navBackground from '../assets/images/nav-bg.jpg'
import Header from './Header'

const NavStyled = styled.header`
  background-image: linear-gradient(
      to right top,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${navBackground});
  background-attachment: scroll, fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 142px;
  padding: 0 3rem;

  display: flex;
  align-items: center;

  .nav-wrapper {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto 0 auto;
  }
`

const Nav = () => (
  <NavStyled>
    <div className="nav-wrapper">
      <Header />
    </div>
  </NavStyled>
)

export default Nav
