import React, { useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import CloseIcon from './CloseIcon'
import { getPopmotionAnimationOptions } from 'framer-motion/types/animation/utils/transitions'

const MenuContainer = styled.div`
  z-index: 1;

  .backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: ${({ isOpen }) =>
      isOpen ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'};
    pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
    transition: all 0.1s linear;
  }

  .menu {
    position: absolute;
    background-color: var(--white);
    top: 0;
    left: 0;
    height: 100vh;
    transform: ${({ isOpen }) =>
      isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.1s ease-in-out;
    padding: 6rem 4rem;

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 5rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--yellow);
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      font-family: 'Open Sans Bold', sans-serif;
      text-transform: none;
      margin-bottom: 2rem;
    }

    a {
      color: var(--black);
      cursor: pointer;
    }
  }
`

type Props = {
  isOpen: boolean
  onClose: () => void
}

const Menu = ({ onClose, isOpen }: Props) => {
  const onKeydown = (event) => {
    if (event.keyCode === 27 && onClose) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeydown, false)
    return () => {
      document.removeEventListener('keydown', onKeydown, false)
    }
  }, [])

  const onBackdropClick = () => {
    if (onClose) {
      onClose()
    }
  }

  const onMenuClick = (event) => {
    if (isOpen) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const goto = (url: string) => {
    if (onClose) {
      onClose()
    }

    navigate(url)
  }

  /*
          <li>
            <a onClick={() => goto('/discounts')}>Скидки</a>
          </li>
  */

  return (
    <MenuContainer {...{ isOpen }}>
      <div
        className={`backdrop ${isOpen ? 'backdrop-on' : ''}`}
        onClick={onBackdropClick}
      ></div>
      <div className={`menu ${isOpen ? 'menu-on' : ''}`} onClick={onMenuClick}>
        <div className="close-button" onClick={onBackdropClick}>
          <CloseIcon />
        </div>
        <ul>
          <li>
            <a onClick={() => goto('/')}>Главная</a>
          </li>
          <li>
            <a onClick={() => goto('/price')}>Прайс</a>
          </li>
          <li>
            <a onClick={() => goto('/extra-services')}>Дополнительные услуги</a>
          </li>
          <li>
            <a onClick={() => goto('/conditions')}>Правила проживания</a>
          </li>
          <li>
            <a onClick={() => goto('/contacts')}>Контакты</a>
          </li>
          <li>
            <a onClick={() => goto('/vacancies')}>Наши вакансии</a>
          </li>
        </ul>
      </div>
    </MenuContainer>
  )
}

export default Menu
