import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import PhoneIcon from './PhoneIconCircle'
import MenuButton from './MenuButton'
import ButtonLink from './ButtonLink'
import Menu from './Menu'
import { CompanyInfo } from '../constants'

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Cormorant SC Bold', serif;
  font-size: 4rem;
  font-weight: 'bold';
  color: var(--white);
  text-transform: uppercase;
  width: 100%;

  .menu-company-wrapper {
    display: flex;
    align-items: center;
  }

  .company-name {
    margin-top: 0.6rem;
    margin-left: 2rem;
    cursor: pointer;
    font-family: 'Tetra';

    @media (min-width: 769px) and (max-width: 992px) {
      margin-left: 7rem;
    }

    @media (max-width: 767px) {
      margin-left: 0;
    }
  }

  .gold {
    color: var(--yellow);
  }

  @media (max-width: 576px) {
    font-size: 3rem;
    flex-direction: column;
  }

  @media (min-width: 577px) and (max-width: 768px) {
    font-size: 4rem;
    flex-direction: column;
  }

  div {
    display: flex;
    flex-direction: row;
  }

  .phone-container {
    @media (max-width: 768px) {
      margin-top: 1.2rem;
    }
  }

  .top-button {
    margin: 0 0 0 3rem;

    @media (max-width: 991px) {
      display: none;
    }
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .phone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
  }

  .mb-05 {
    margin-bottom: 0.5rem;
  }
`

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onMenu = () => setOpen(true)

  const onCompanyName = () => {
    navigate('/')
  }

  return (
    <HeaderStyled>
      <div className="menu-company-wrapper">
        <Menu {...{ isOpen, onClose }} />
        <MenuButton onClick={onMenu} />
        <span className="company-name" onClick={onCompanyName}>
          <span className="gold">Золотая</span> звезда
        </span>
      </div>

      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="phone-container"
      >
        <a href={`tel:${CompanyInfo.phone2}`}>
          <motion.div
            animate={{ rotate: [0, -30, 30, 0] }}
            transition={{
              rotate: {
                duration: 0.3,
                yoyo: 1,
                ease: 'easeInOut',
                delay: 1,
              },
            }}
          >
            <PhoneIcon />
          </motion.div>
          <div className="phone">
            <div className="mb-05">{CompanyInfo.phone2}</div>
            <div>{CompanyInfo.phone}</div>
          </div>
        </a>
        <div className="top-button">
          <ButtonLink href="/book-a-room" dark>
            Забронировать номер
          </ButtonLink>
        </div>
      </motion.div>
    </HeaderStyled>
  )
}

export default Header
