import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby'

import navBackground from '../assets/images/nav-home-bg.jpg'
import banner from '../assets/images/2024-06-14_tsar-gory.jpg'
import VkIcon from './VkIcon'
import TelegramIcon from './TelegramIcon'
import { CompanyInfo } from '../constants'
import ButtonLink from './ButtonLink'
import Header from './Header'

const BANNER_URL = ''

const NavStyled = styled.nav`
  background-image: linear-gradient(
      to right top,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${navBackground});
  background-attachment: fixed, scroll !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 0 3rem;

  @media (max-width: 992px) {
    // height: 868px;
  }

  @media (max-width: 768px) {
    // height: 798px;
  }

  @media (max-width: 576px) {
    // height: 728px;
    padding: 0 1.5rem;
  }

  .nav-wrapper {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto 0 auto;
  }

  .button {
    margin: 4rem 0 1rem 0;

    @media (max-width: 576px) {
      margin: 2rem 0 1rem 0;
    }
  }
`

const HeaderWrapper = styled.div`
  padding: 4rem 0 4rem 0;

  @media (max-width: 576px) {
    padding: 2.4rem 0 2.4rem 0;
  }

  @media (min-width: 768px) {
    padding: 4rem 0 4rem 0;
  }

  @media (min-width: 992px) {
    padding 4rem 0 10rem 0;
  }
`

const DivStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: flex-start;

    div {
      text-align: left;
    }
  }

  .button-wrapper {
    @media (min-width: 992px) {
      display: none;
    }
  }

  .mb2 {
    margin-bottom: 2rem;

    @media (max-width: 576px) {
      margin-bottom: 1.5rem;
    }
  }

  .w70 {
    @media (min-width: 992px) {
      width: 80%;
    }
  }

  .headline {
    margin: 4rem 0 4rem 0;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    font-size: 5rem;
    color: var(--white);
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
      margin: 6rem 0 6rem 0;
      font-size: 5rem;
    }

    @media (min-width: 1020px) {
      font-size: 6rem;
    }

    @media (max-width: 768px) {
      font-size: 4rem;
      margin: 2.5rem 0 2.5rem 0;
    }

    @media (max-width: 576px) {
      font-size: 3rem;
      margin: 2.5rem 0 2.5rem 0;
    }

    span {
      color: var(--yellow);
    }
  }
`

const LinksStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  @media (min-width: 992px) {
    justify-content: flex-start;
  }

  div {
    display: flex;
    flex-direction: row;
    margin: 0.8rem 0;
  }

  a {
    color: var(--white);
    font-size: 1.8rem;
    text-decoration: underline;
    margin: 0 1rem 0 1rem;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const Left = styled.div`
  width: 100%;

  @media (min-width: 992px) {
    width: 61%;
  }
`
const Right = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: flex;
    align-self: stretch;
    margin-bottom: 8rem;
    margin-left: 4rem;
    width: 39%;
    max-width: 39%;
  }
`
const BannerImage = styled.img`
  object-fit: contain;
`
const BannerMobile = styled.a`
  display: flex;
  justify-content: center;
  max-height: 460px;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    max-height: 700px;
  }

  @media (min-width: 820px) {
    max-height: 800px;
  }

  @media (min-width: 992px) {
    display: none;
  }
`

const Nav = () => {
  const onCompanyName = () => {
    navigate('/')
  }

  return (
    <NavStyled>
      <div className="nav-wrapper">
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>

        <Row>
          <Left>
            <DivStyled>
              <div>
                <div className="subheading w70">
                  Приглашаем Вас отдохнуть в Хакасии на берегу озера Белё
                </div>
                <div className="headline">
                  <span>пришло время </span>
                  <br /> вырваться из душного мегаполиса
                </div>
                <div className="subheading mb2 w70">
                  Вас ждут теплые и уютные номера, кедровая баня, мангал, свежий
                  воздух, активный отдых и море позитивных эмоций.
                </div>
              </div>

              <motion.div
                className="button-wrapper"
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="button">
                  <ButtonLink href="/book-a-room" dark>
                    Забронировать номер
                  </ButtonLink>
                </div>
              </motion.div>
            </DivStyled>

            {BANNER_URL ? (
              <BannerMobile href={BANNER_URL} target="_blank">
                <BannerImage src={banner} />
              </BannerMobile>
            ) : null}

            <LinksStyled>
              <div>
                <a href={`mailto:${CompanyInfo.email}`}>{CompanyInfo.email}</a>
              </div>
              <div className="social-container">
                <a href={CompanyInfo.telegram} target="_blank">
                  <TelegramIcon />
                </a>
                <a href={CompanyInfo.vk} target="_blank">
                  <VkIcon />
                </a>
              </div>
            </LinksStyled>
          </Left>

          {BANNER_URL ? (
            <Right>
              <a href={BANNER_URL} target="_blank">
                <BannerImage src={banner} />
              </a>
            </Right>
          ) : null}
        </Row>
      </div>
    </NavStyled>
  )
}

export default Nav
