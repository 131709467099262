import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 3rem;
  height: 3rem;

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: var(--yellow);
  }
`

const MailIcon = () => (
  <Container>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50.875"
      height="56.781"
      viewBox="0 0 50.875 56.781"
    >
      <path
        d="M49.123 21.012l-.044-.048-5.438-5.553v-.868a4.392 4.392 0 0 0-4.353-4.42h-.857l-.018-.022-7.9-8.019a7.028 7.028 0 0 0-10.034 0l-3.695 3.731a1.51 1.51 0 0 0 0 2.113 1.457 1.457 0 0 0 2.081.007l3.7-3.734a4.116 4.116 0 0 1 5.875 0l5.836 5.927H11.587a4.392 4.392 0 0 0-4.353 4.42v.913l-5.3 5.353A7.237 7.237 0 0 0-.003 25.76v23.806a7.161 7.161 0 0 0 7.1 7.207h36.685a7.16 7.16 0 0 0 7.1-7.207V25.757a7.269 7.269 0 0 0-1.759-4.745zM7.237 19.675v1.693a1.472 1.472 0 1 0 2.943 0v-6.822a1.422 1.422 0 0 1 1.41-1.43h27.7a1.421 1.421 0 0 1 1.409 1.43v6.822a1.472 1.472 0 1 0 2.944 0V19.64l2.2 2.231-13.683 13.9-1.637-1.662a7.018 7.018 0 0 0-10.037 0l-1.638 1.662L5.115 21.824zm40.7 29.891a4.191 4.191 0 0 1-4.154 4.218H7.095a4.191 4.191 0 0 1-4.154-4.218V25.757a4.324 4.324 0 0 1 .308-1.6l13.512 13.718-9.9 10.048a1.513 1.513 0 0 0 0 2.114 1.456 1.456 0 0 0 2.082 0l13.613-13.825a4.111 4.111 0 0 1 5.875 0l13.862 14.077a1.455 1.455 0 0 0 2.081 0 1.51 1.51 0 0 0 0-2.113L34.237 37.875l13.422-13.63a4.263 4.263 0 0 1 .277 1.51v23.806zm-31.6-28.93h18.2a1.5 1.5 0 0 0 0-2.99h-18.2a1.5 1.5 0 0 0 0 2.987zm19.678 5.68a1.483 1.483 0 0 0-1.472-1.5H16.337a1.495 1.495 0 0 0 0 2.989h18.2a1.483 1.483 0 0 0 1.475-1.492z"
        fillRule="evenodd"
      ></path>
    </svg>
  </Container>
)

export default MailIcon
