// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-book-a-room-tsx": () => import("./../../../src/pages/book-a-room.tsx" /* webpackChunkName: "component---src-pages-book-a-room-tsx" */),
  "component---src-pages-conditions-tsx": () => import("./../../../src/pages/conditions.tsx" /* webpackChunkName: "component---src-pages-conditions-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-discounts-tsx": () => import("./../../../src/pages/discounts.tsx" /* webpackChunkName: "component---src-pages-discounts-tsx" */),
  "component---src-pages-extra-services-tsx": () => import("./../../../src/pages/extra-services.tsx" /* webpackChunkName: "component---src-pages-extra-services-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-price-tsx": () => import("./../../../src/pages/price.tsx" /* webpackChunkName: "component---src-pages-price-tsx" */),
  "component---src-pages-thank-you-for-booking-a-room-tsx": () => import("./../../../src/pages/thank-you-for-booking-a-room.tsx" /* webpackChunkName: "component---src-pages-thank-you-for-booking-a-room-tsx" */),
  "component---src-pages-vacancies-tsx": () => import("./../../../src/pages/vacancies.tsx" /* webpackChunkName: "component---src-pages-vacancies-tsx" */)
}

