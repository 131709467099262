import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  a {
    padding: 1rem 2rem 1rem 2rem;
    text-align: center;
  }
`

// < Link to = "/discounts" > Скидки</Link>

const FooterMenu = () => (
  <Wrapper>
    <Link to="/price">Прайс</Link>
    <Link to="/extra-services">Дополнительные услуги</Link>
    <Link to="/conditions">Правила проживания</Link>
    <Link to="/contacts">Контакты</Link>
    <Link to="/vacancies">Наши вакансии</Link>
  </Wrapper>
)

export default FooterMenu
