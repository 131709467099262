export default {
  email: 'info@bele-star.ru',
  phone: '+7 (901) 235-80-02',
  additionalPhone: '+7 (390) 232-80-02',
  phone2: '+7 (391) 515-80-02',
  address: 'Республика Хакасия, Ширинский район',
  route:
    'https://yandex.ru/maps/?ll=90.161960%2C54.706168&mode=routes&rtext=~54.706168%2C90.161960&rtt=auto&ruri=~&z=18',
  instagram: 'https://www.instagram.com/bele_golden_star/',
  vk: 'https://vk.com/public211224842',
  telegram: 'https://t.me/bele_star',
  price: 'https://disk.yandex.ru/i/KrGMQ1wPAMuq_g',
  priceExtra: 'https://disk.yandex.ru/i/sI8usz1PFsCSyw',
}
