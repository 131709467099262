import React, { ReactElement } from 'react'
import 'normalize.css'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import NavHome from '../components/NavHome'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

const ContentStyles = styled.div`
  background: white;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  html,
  body {
    height: 100%;
  }

  body {
    display: flex;
    flex-direction: column;
  }

  .content-wrapper {
    flex: 1 0 auto;
  }
`

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
      }
    }
  }
`

type Props = {
  path: string
  children: ReactElement | ReactElement[]
}

const Layout = ({ path, children }: Props) => {
  const isRootPage = path === '/'
  const { site } = useStaticQuery(query)

  const { title, description, siteUrl, image } = site.siteMetadata

  return (
    <>
      <Helmet {...{ title }}>
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Золотая Звезда" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://bele-star.ru" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
      </Helmet>

      <GlobalStyles />
      <Typography />

      <ContentStyles>
        {isRootPage ? <NavHome /> : <Nav />}
        <div className="content-wrapper">{children}</div>
        <Footer />
      </ContentStyles>
    </>
  )
}

export default Layout
